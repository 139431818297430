const nextFrame = () => {

    return new Promise(resolve => {
        requestAnimationFrame(() => {
            requestAnimationFrame(resolve);
        });
    });
};

const afterTransition = element => {

    return new Promise<void>(resolve => {
        const value = getComputedStyle(element)
            .transitionDuration
            .replace("s", "");
        const duration = Number(value) * 1000;
        setTimeout(resolve, duration);
    });
};

const cycleFeatureSelectors = (
    selectors: NodeListOf<Element>,
    selectedImage: HTMLImageElement,
) => {
    const selectedClass = "selected";

    let selectedIndex = 0;
    setInterval(
        async () => {
            selectors[selectedIndex].classList.remove(selectedClass);
            selectedIndex += 1;
            selectedIndex %= selectors.length;
            selectors[selectedIndex].classList.add(selectedClass);

            const img = selectors[selectedIndex].querySelector("img");
            selectedImage.style.opacity = "0";
            await afterTransition(selectedImage);
            selectedImage.src = img.src;
            await nextFrame();
            selectedImage.style.opacity = "1";
        },
        3000,
    );
};


const main = () => {
    const locationSelectors = document.querySelectorAll(".location-selector");
    const selectedLocationImage = document.querySelector("#selected-location") as HTMLImageElement;
    cycleFeatureSelectors(locationSelectors, selectedLocationImage);

    const styleSelectors = document.querySelectorAll(".style-selector");
    const selectedStyleImage = document.querySelector("#selected-style") as HTMLImageElement;
    cycleFeatureSelectors(styleSelectors, selectedStyleImage);
};

window.addEventListener("load", main);
